import * as React from "react"
import Menu from "../component/Menu";
import Header from "../component/Header";

class Ctkm extends React.Component {

    render() {
        return (
            <div id="container" className="container-fluid overflow-hidden package-page">
                <div className="menu-overlay"/>
                <Menu/>
                <Header/>
                <div className="row">
                    <div className="col-12">
						<img src={ require("../asset/images/ctkm-7-2.jpg") } style={{ width: '100%' }} />
                        <div className="mt-3" style={{textAlign: "center", fontWeight: 600, fontSize: "20px"}}>
                            CTKM NGHE NHẠC BOLERO THẢ GA & NHẬN QUÀ THỎA THÍCH
                        </div>
                        <div className="mt-3">
                            <span style={{fontWeight: 600}}>1. Nội dung chương trình:</span>
                        </div>
                        <div className="mt-1">
                            - Thuê bao đăng ký thành công gói ngày IB và IT và duy trì 03 ngày liên tiếp (bao gồm ngày đầu đăng ký) sẽ được tặng 10.000 VNĐ vào tài khoản chính;
                        </div>
                        <div className="mt-1">
                            <span style={{fontWeight: 600}}>- Gói IT được tặng 20 phút thoại nội mạng MobiFone.</span>
                        </div>
                        <div className="mt-1">
                            <span style={{fontWeight: 600}}>- Gói IB được tặng 200MB tốc độ cao</span>
                        </div>
						 <div className="mt-1">
                            - Khách hàng sẽ được nghe, xem và tải thưởng thức hàng nghìn video trên trang http://ibolero.vn mới nhất, Hot nhất, chất lượng Full HD mà KHÔNG MẤT 1 ĐỒNG cước data 3G/4G.
                        </div>
                        <div className="mt-3">
                            <span style={{fontWeight: 600}}>2. Thời gian khuyến mại:</span> Từ 0h00:00 ngày 15/07/2024 đến hết 23h59:59 ngày 28/08/2024.
                        </div>
                        <div className="mt-3">
                            <span style={{fontWeight: 600}}>3. Đối tượng tham gia:</span> Thuê bao trả trước hoặc trả sau của MobiFone đang hoạt động 2 chiều.
                        </div>
                        <div className="mt-3">
                            <span style={{fontWeight: 600}}>4. Cách thức tham gia:</span>
                        </div>
                        <div className="mt-1">
                            - Để tham gia chương trình khuyến mãi, khách hàng cần soạn tin nhắn theo cú pháp ở bảng dưới đây:
                        </div>
                        <table className="table table-bordered mt-3" style={{fontSize: "12px"}}>
                            <thead>
                            <tr>
                                <th scope="col">STT</th>
                                <th scope="col">Kênh tương tác</th>
                                <th scope="col">Thực hiện</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1.</td>
                                <td>Đăng ký</td>
                                <td>
                                    <span style={{fontWeight: 600}}>Gói IT và IB giá 5.000đ/ngày</span>
                                </td>
                            </tr>
                            <tr>
                                <td>1.1</td>
                                <td>Qua kênh SMS</td>
                                <td>
                                    Soạn <span style={{fontWeight: 600}}>IB</span> gửi <span style={{fontWeight: 600}}>999</span> (Nhận ngay ưu đãi 200MB data tốc độ cao)
									<br/>
									Soạn <span style={{fontWeight: 600}}>IT</span> gửi <span style={{fontWeight: 600}}>999</span> (Nhận ngay 20 phút thoại nội mạng MobiFone)

                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Tra cứu mật khẩu</td>
                                <td>
                                    Soạn&nbsp;
                                    <span style={{fontWeight: 600}}>MK</span>&nbsp;
                                    gửi&nbsp;
                                    <span style={{fontWeight: 600}}>9355</span>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Hủy dịch vụ</td>
                                <td>
                                    Soạn&nbsp;
                                    <span style={{fontWeight: 600}}>HUY IT (hoặc HUY IB)</span>&nbsp;
                                    gửi&nbsp;
                                    <span style={{fontWeight: 600}}>999</span>&nbsp;và làm theo hướng dẫn
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="mt-3">
                            Để biết thêm thông tin chi tiết về chương trình, khách hàng vui lòng gọi 9090 (200đ/phút).
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Ctkm
