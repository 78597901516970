import * as React from "react"
import {CategoryState} from "../reducer/type/category";
import StateInterface from "../reducer/type";
import {bindActionCreators, Dispatch} from "redux";
import * as CategoryAction from "../action/category";
import {connect} from "react-redux";
import Menu from "../component/Menu";
import Header from "../component/Header";
import LoadingScreen from "../component/LoadingScreen";
import {FavoriteListItemInterface} from "../reducer/type/category";
import {Link} from "react-router-dom";
import LoadMore from "../component/LoadMore";
import * as $ from "jquery";
import ModalDownload from "../component/ModalDownload";
import ModalAlert from "../component/ModalAlert";
import {HomeState} from "../reducer/type/home";
import * as HomeAction from "../action/home";
import * as ls from "local-storage";
import * as AuthAction from "../action/auth";
import {AuthState} from "../reducer/type/auth";

interface StatePropsInterface {
    category: CategoryState,
    home: HomeState,
    auth: AuthState
}

interface ParamPropsInterface {
    location: any
}

interface DispatchPropsInterface {
    actions: {
        getCategoryAction: any,
        listFavoriteAction: any,
        removeListFavoriteAction: any,
        loadmoreListFavoriteAction: any,
        getInfoAction: any,
    }
}

const mapStateToProps = (state: StateInterface) => ({
    category: state.category,
    home: state.home,
    auth: state.auth
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators({
        getCategoryAction: CategoryAction.getCategoryAction,
        listFavoriteAction: CategoryAction.listFavoriteAction,
        removeListFavoriteAction: CategoryAction.removeListFavoriteAction,
        loadmoreListFavoriteAction: CategoryAction.loadmoreListFavoriteAction,
        getInfoAction: AuthAction.getInfoAction,
    }, dispatch)
});

type PropsInterface = StatePropsInterface & DispatchPropsInterface & ParamPropsInterface

interface CurrentStateInterface {
    loading: boolean,
    loadmore: boolean,
    menu?: FavoriteListItemInterface,
    linkDownload: object[]
}

class Favorite extends React.Component <PropsInterface, CurrentStateInterface> {

    constructor (props: PropsInterface) {
        super(props);
        this.props.actions.getCategoryAction();
        if (typeof ls.get<string>('token') == 'string') {
            this.props.actions.getInfoAction();
        }
        this.state = {
            loading: true,
            loadmore: false,
            menu: null,
            linkDownload: []
        }
        this.trackScrolling = this.trackScrolling.bind(this)
    }

    componentDidMount() {
        $('.menu-overlay-2').height($(window).height());
        this.props.actions.listFavoriteAction();

        $('.menu-overlay-2').on('click', function () {
            $('.favorite-menu-content').css({
                bottom: '-300px'
            });
            $(this).hide();
        });

        // Scroll
        document.addEventListener('scroll', this.trackScrolling, false);
        document.addEventListener('resize', this.trackScrolling, false);
    }

    componentDidUpdate(prevProps) {
        if (this.props.category.favorite !== prevProps.category.favorite) {
            this.setState({
                loading: false,
                loadmore: false
            })
        }
        if (this.props.location !== prevProps.location) {
            this.setState({
                loading: true
            });
            this.props.actions.listFavoriteAction();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling, false);
        document.removeEventListener('resize', this.trackScrolling, false);
    }

    trackScrolling () {
        const wrappedElement = document.getElementById('container');
        if ( (wrappedElement.getBoundingClientRect().bottom - 74) <= window.innerHeight ) {
            if (typeof this.props.category.detail.links.next != 'undefined' && this.state.loadmore == false) {
                this.setState({
                    loadmore: true
                });
                this.props.actions.loadmoreListFavoriteAction(this.props.category.favorite.links.next);
            }
        }
    }

    handleMenuFavorite (data) {
        this.setState({
            menu: data
        });
        $('.menu-overlay-2').show();
        $('.favorite-menu-content').css({
            bottom: 0
        });
    }

    handleMenuClose () {
        $('.favorite-menu-content').css({
            bottom: '-300px'
        });
        $('.menu-overlay-2').hide();
    }

    handleRemoveFavorite () {
        this.props.actions.removeListFavoriteAction(this.state.menu.id);
        $('.favorite-menu-content').css({
            bottom: '-300px'
        });
        $('.menu-overlay-2').hide();
        $('.modal-alert-text').text('Bạn đã xóa video ' + this.state.menu.name + ' khỏi video yêu thích thành công.');
        $('#modal-alert').modal('show');
    }

    handleDownloadAction () {
        this.setState({
            linkDownload: this.state.menu.link_download
        });
        if (typeof ls.get<string>('token') == 'string') {
            if (typeof this.props.auth.data.package != "undefined" && this.props.auth.data.package != '') {
                $('#modal-download').modal("show");
            } else {
                $('#modal-alert').modal('show');
            }
        } else {
            $('#modal-alert').modal('show');
        }

    }

    render() {
        let loadingScreen;
        if (this.state.loading) {
            loadingScreen = (<LoadingScreen/>)
        }

        let loadmoreView;
        if (this.state.loadmore) {
            loadmoreView = (<LoadMore/>)
        }

        let listSong;
        if (this.props.category.favorite.list.length == 0) {
            listSong = (
                <div className="col-12">
                        Chưa có video yêu thích nào
                </div>
            );
        } else {
            listSong = this.props.category.favorite.list.map((item: FavoriteListItemInterface, key: number) => {
                return (
                    <div className="col-12" key={key}>
                        <div className="row">
                            <Link to={'/play/' + item.id + '-' + item.slug} className="col-6 pr-2">
                                <img className="video-relate-img" src={item.thumbnail_url}/>
                            </Link>
                            <div className="col-5 pl-0 pr-0">
                                <Link to={'/play/' + item.id + '-' + item.slug}
                                      className="video-relate-title float-left">
                                    {item.name}
                                </Link>
                                <div className="clearfix"/>
                                <div className="video-relate-sub">
                                    {item.artist}
                                </div>
                            </div>
                            <div className="col-1 pr-0 pl-0">
                                <div className="favorite-menu-group" onClick={this.handleMenuFavorite.bind(this, item)}>
                                    <img className="favorite-menu" src={require("../asset/images/icon-menu-sub.png")}/>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }

        let menuFavorite;
        menuFavorite = this.state.menu ? (
            <div className="favorite-menu-content">
                <div className="row favorite-menu-content-1">
                    <div className="col-4">
                        <img className="video-relate-img" src={ this.state.menu.thumbnail_url }/>
                    </div>
                    <div className="col-8 pl-0">
                        <div className="video-relate-title float-left">
                            { this.state.menu.name }
                        </div>
                        <div className="clearfix"/>
                        <div className="video-relate-sub">
                            { this.state.menu.artist }
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='video-menu-item' onClick={this.handleRemoveFavorite.bind(this)}>
                            <img src={ require("../asset/images/icon-heart.png") } style={{
                                width: 18
                            }}/>
                            Xóa khỏi video yêu thích
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='video-menu-item' onClick={this.handleDownloadAction.bind(this)}>
                            <img src={ require("../asset/images/icon-download.png") } style={{
                                width: 18
                            }}/>
                            Tải về máy
                        </div>
                    </div>
                </div>
                <div className="row favorite-menu-content-2">
                    <div className="video-menu-close" onClick={this.handleMenuClose}>
                        Đóng
                    </div>
                </div>
            </div>
        ) : (<div className="favorite-menu-content"/>);

        let modalDownload;
        if (typeof this.state.menu == "object" && this.state.menu != null) {
            modalDownload = (<ModalDownload link={this.state.linkDownload} song_id={this.state.menu.id}/>);
        }

        let modalAlert;
        if (typeof this.props.auth.data.package != "undefined" && this.props.auth.data.package != '') {
            modalAlert = (<ModalAlert text='Bạn cần đăng ký gói cước iBolero để sử dụng tính năng này!' button={'exit'}/>);
        } else {
            modalAlert = (<ModalAlert text='Bạn cần đăng ký gói cước iBolero để sử dụng tính năng này!' button={'package'}/>);
        }

        return (
            <div id="container" className="container-fluid overflow-hidden">
                <div className="menu-overlay"/>
                <div className="menu-overlay-2"/>
                <Menu/>
                <Header logo="Video yêu thích"/>
                {modalDownload}
                {modalAlert}
                {loadingScreen}

                <div className="row">
                    <div className="col-12">
                        <div className="favorite-text">I Video của bạn</div>
                    </div>
                </div>
                <div className="row list-content">
                    {listSong}
                    {loadmoreView}
                </div>

                { menuFavorite }
            </div>
        );
    }

}

export default connect<StatePropsInterface, DispatchPropsInterface>(
    mapStateToProps,
    mapDispatchToProps
)(Favorite);
