import * as React from "react"
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import Header from '../component/Header'
import Menu from '../component/Menu'
import HomeContent from "../component/HomeContent";
import HomeContentTwo from "../component/HomeContentTwo";
import HomeArtist from "../component/HomeArtist";

import Slider, {LazyLoadTypes} from 'react-slick'
import { Link } from 'react-router-dom'

import StateInterface from '../reducer/type'
import {CategoryItemInterface, CategoryState} from '../reducer/type/category'
import * as CategoryAction from '../action/category'
import * as AuthAction from '../action/auth'
import {BannerItemInterface, HomeItemInterface, HomeState, HomeVideoItemInterface} from "../reducer/type/home";
import * as HomeAction from '../action/home'
import LoadingScreen from "../component/LoadingScreen";
import * as ls from "local-storage";
import * as $ from 'jquery'
import HomeLienkhuc from "../component/HomeLienkhuc";


interface StatePropsInterface {
    category: CategoryState,
    home: HomeState
}

interface DispatchPropsInterface {
    actions: {
        getCategoryAction: any,
        infoHomeAction: any,
        infoArtistAction: any,
        getInfoAction: any,
        infoBannerAction: any,
        infoRankAction: any,
        infoLienkhucAction: any
    }
}

const mapStateToProps = (state: StateInterface) => ({
    category: state.category,
    home: state.home
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators({
        getCategoryAction: CategoryAction.getCategoryAction,
        infoHomeAction: HomeAction.infoHomeAction,
        infoArtistAction: HomeAction.infoArtistAction,
        getInfoAction: AuthAction.getInfoAction,
        infoBannerAction: HomeAction.infoBannerAction,
        infoRankAction: HomeAction.infoRankAction,
        infoLienkhucAction: HomeAction.infoLienkhucAction
    }, dispatch)
});

type PropsInterface = StatePropsInterface & DispatchPropsInterface

interface CurrentState {
    activeSlide: any
}

class Home extends React.Component <PropsInterface, CurrentState> {

    constructor (props: PropsInterface) {
        super(props);
        this.state = {
            activeSlide: 9
        };
        this.props.actions.getCategoryAction();
        this.props.actions.infoHomeAction();
        this.props.actions.infoArtistAction();
        this.props.actions.infoBannerAction();
        this.props.actions.infoRankAction();
        this.props.actions.infoLienkhucAction();
        if (typeof ls.get<string>('token') == 'string') {
            this.props.actions.getInfoAction();
        }
    }

    componentDidMount() {
    }

    handleHomeSlide () {
        let data = $('.slick-center .home-item').data('id');
        console.log(data);
        this.setState({
            activeSlide: data
        })
    }

    handleClickMore(data) {
        window.location = data
    }

    render() {
        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            autoplay: true,
            //lazyLoad: "ondemand" as LazyLoadTypes
        };

        let settingsCate = {
            initialSlide: 9,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            //slidesToScroll: 1,
            swipeToSlide: true,
            centerPadding: "0px",
            centerMode: true,
            autoplay: false,
            beforeChange: (current, next) => this.setState({ activeSlide: next }),
            //lazyLoad: "ondemand" as LazyLoadTypes
        };

        let loadingScreen;
        if (this.props.home.data.length == 0) {
            loadingScreen = (<LoadingScreen/>)
        }

        let homeContent = this.props.home.data.map((item: HomeItemInterface, key: number) => {
            if (this.props.category.data.length > 0) {
                if (this.state.activeSlide < 0) {
                    this.setState({
                        activeSlide: 9
                    });
                }
                if (this.state.activeSlide == key) {
                    let listHomeContent = (<HomeContent video={item.list}/>);
                    let listViewMore = (
                        <div className="col-12 text-center">
                            <div onClick={ this.handleClickMore.bind(this, '/danh-muc/' + item.id + '-' + item.slug) } className="btn content-view-more">
                                Xem thêm
                                <img className="content-view-more-img" src={require("../asset/images/icon-right.png")}/>
                            </div>
                        </div>
                    );

                    let titleHomeContent = (
                        <div key={key} className="row list-content mt-3">
                            {listHomeContent}
                            {listViewMore}
                        </div>
                    )
                    return titleHomeContent;
                } else {
                    let listHomeContent = (<HomeContent video={item.list}/>);
                    let listViewMore = (
                        <div className="col-12 text-center">
                            <div onClick={ this.handleClickMore.bind(this, '/danh-muc/' + item.id + '-' + item.slug) } className="btn content-view-more">
                                Xem thêm
                                <img className="content-view-more-img" src={require("../asset/images/icon-right.png")}/>
                            </div>
                        </div>
                    );

                    let titleHomeContent = (
                        <div key={key} className="row list-content mt-3" style={{display: "none"}}>
                            {listHomeContent}
                            {listViewMore}
                        </div>
                    )
                    return titleHomeContent;
                }
            }
        });

        let artistContent = (
            <div className="row list-content">
                <div className="col-12 home-head">
                    Ca sĩ
                </div>
                <HomeArtist artist={ this.props.home.artist }/>
            </div>
        );

        let bannerContent = this.props.home.banner.map((item: BannerItemInterface, key) => {
            return (
                <div className="slide-item" key={key}>
                    <Link to={'/play/'+ item.id + '-' + item.slug}>
                        <img className="slide-img" src={ item.thumbnail_url }/>
                    </Link>
                </div>
            );
        });

        let homeSlide = this.props.category.data.map((item: CategoryItemInterface, key) => {

            let imgSlide;
            if (item.slug == 'nhac-xuan-tuyen-chon') {
                imgSlide = (<img src={ require("../asset/images/category-xuan.png") }/>);
            } else if (item.slug == 'nhac-song-dinh-cao') {
                imgSlide = (<img src={ require("../asset/images/category-live.png") }/>);
            } else if (item.slug == 'bolero-kinh-dien') {
                imgSlide = (<img src={ require("../asset/images/category-play.png") }/>);
            } else if (item.slug == 'nhac-phat') {
                imgSlide = (<img src={ require("../asset/images/category-budda.png") }/>);
            } else if (item.slug == 'tuyet-dinh-song-ca') {
                imgSlide = (<img src={ require("../asset/images/category-singer.png") }/>);
            } else if (item.slug == 'nhac-tru-tinh') {
                imgSlide = (<img src={ require("../asset/images/category-love.png") }/>);
            } else if (item.slug == 'bolero-remix') {
                imgSlide = (<img src={ require("../asset/images/category-remix.png") }/>);
            } else if (item.slug == 'bolero-girl') {
                imgSlide = (<img src={ require("../asset/images/category-girl.png") }/>);
            } else if (item.slug == 'nhac-vang') {
                imgSlide = (<img src={ require("../asset/images/category-music.png") }/>);
            } else if (item.slug == 'karaoke') {
                imgSlide = (<img src={ require("../asset/images/category-karaoke.png") }/>);
            } else {
                imgSlide = (<img src={ require("../asset/images/category-music.png") }/>);
            }

            return (
                 <div onClick={ this.handleClickMore.bind(this, '/danh-muc/' + item.id + '-' + item.slug) } className="home-item" data-id={item.id} key={key}>
                     <div>
                         {imgSlide}
                     </div>
                     <div className="home-item-text" style={{color: '#000'}}>{item.name}</div>
                 </div>
             );
        });

        let bxhContent = this.props.home.rank.map( (item: HomeVideoItemInterface, key) => {

            let rankIndex;
            if (key == 0) {
                rankIndex = (
                    <div className='clearfix'>
                        <img className="bxh-item-score" src={ require('../asset/images/bxh-01.png') }/>
                        <img className="bxh-item-score pl-2" src={ require('../asset/images/bxh-star.png') }/>
                    </div>
                );
            } else if (key == 1) {
                rankIndex = (
                    <div className='clearfix'>
                        <img className="bxh-item-score" src={ require('../asset/images/bxh-02.png') }/>
                    </div>
                );
            } else if (key == 2) {
                rankIndex = (
                    <div className='clearfix'>
                        <img className="bxh-item-score" src={ require('../asset/images/bxh-03.png') }/>
                    </div>
                );
            } else if (key == 3) {
                rankIndex = (
                    <div className='clearfix'>
                        <img className="bxh-item-score" src={ require('../asset/images/bxh-04.png') }/>
                    </div>
                );
            }

            return (
                <div className='col-12 pr-2 pl-2' key={key}>
                    <Link to={'/bang-xep-hang/play/'+ item.id + '-' + item.slug} className='bxh-item'>
                        <div className='row'>
                            <div className='col-6 pr-0'>
                                <img className="bxh-item-img" src={ item.thumbnail_url }/>
                            </div>
                            <div className='col-6'>
                                {rankIndex}
                                <div className='bxh-divide'/>
                                <div className='bxh-item-name'>{ item.name }</div>
                                <div className='bxh-item-singer'>{ item.artist }</div>
                            </div>
                        </div>
                    </Link>
                </div>
            );
        });

        let lienkhucContent = (
            <div className="row list-content">
                <div className="col-12 home-head">
                    Liên khúc
                </div>
                <HomeLienkhuc artist={ this.props.home.lienkhuc }/>
            </div>
        );

        return (
            <div className="container-fluid overflow-hidden">
                <div className="menu-overlay"/>
                <Menu/>
                <Header/>
                {loadingScreen}

                {/* Slide Banner */}
                <div className="row banner-slide">
                    <div className="col-12 pr-0 pl-0">
                        <Slider {...settings} className="banner-slider">
                            {bannerContent}
                        </Slider>
                    </div>
                </div>

                {/*<div className="row">*/}
                {/*    <div className="col-12 pr-0 pl-0">*/}
                {/*        <a href="/ctkm">*/}
                {/*            <img src={ require("../asset/images/ctkm-7.jpg") } style={{ width: '100%' }} />*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="row bxh-group">
                    <div className='col-12'>
                        <div className="bxh-head">Bảng xếp hạng</div>
                    </div>
                    {bxhContent}
                    <div className="col-12 text-center">
                        <Link to={ '/bang-xep-hang' } className="btn content-view-more text-white">
                            Xem thêm
                            <img className="content-view-more-img" src={ require("../asset/images/icon-right-2.png") }/>
                        </Link>
                    </div>
                </div>

                <div className="row home-group">
                    <div className="col-12 home-head">
                        Hôm nay bạn muốn nghe gì?
                    </div>
                    <div className="col-12 pr-0 pl-0 mt-3">
                        <Slider {...settingsCate} className="home-slider">
                            {homeSlide}
                        </Slider>
                    </div>

                    { homeContent }
                </div>
                { artistContent }
                { lienkhucContent }

                <div className='row footer'>
                    <div className="col-12">
                        <div className='footer-text'>
                            Cơ quan chủ quản: Tổng Công ty Viễn thông MobiFone <br/>
                            Copyright 2019 © Mobifone. All rights reserved
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default connect<StatePropsInterface, DispatchPropsInterface>(
    mapStateToProps,
    mapDispatchToProps
)(Home);
